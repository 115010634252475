import React from "react";
import styled from "styled-components";
import Footer from "../footer/Footer";
import { Link } from "react-router-dom";

import { useLocation } from "react-router-dom";
import Nav from "../Nav/Nav";

import why from "../images/why.png";
import solve from "../images/solve.png";
// import track from "../images/track.png";

const Home = () => {
  const { pathname } = useLocation();

  if (pathname === "/") {
    document.title = "The Mary Babatola Bifarin Grant";
  }

  return (
    <div>
      <Nav />
      <HeroSection>
        <div className="main-header">
          <div className="hr-sub-header">
            <p>
            MBB microgrant program will give grant money to <span>at least</span> two teams / individuals in Nigeria to do something impactful.
            </p>
        
          </div>
          <div className="sub-header">
            <h1>
              The <span> Mary Babatola Bifarin Grant </span> [MBB Grant]

            </h1>
          </div>
          <div className="btn-apply">
          <a
              href="https://docs.google.com/forms/d/1UsRZGajNXuMhh_9DCamMfX5I6F1KbblwvgZYrSKlSEE/edit"
              target="_blank"
              rel="noreferrer"
              className="apply"
            >
              Apply Now
            </a>
          </div>
        </div>
      </HeroSection>

      <HeroContent>
        <div className="content-1">
          <div className="content-col">
            <div className="img-container">
              <img src={why} alt="what and why" />
            </div>
          </div>
          <div className="content-col">
            <div className="txt">
              <p>
                The MBB grant by Olatomiwa Bifarin, honors the memory of his
                late grandmother, Mary Babatola Bifarin [1927-2010]. A dedicated maternity nurse that hailed from Nigeria.
                Further information about her life can be found
                {" "}
                <span class= "here">
                  {" "}
                  <Link to="/About">here.</Link>{" "}
                </span>This personal grantmaking endeavor is an experiment to foster
                innovative thinking amongst young people in Nigeria. To foster
                foresight and responsibility by taking on challenging problems
                in the society.
              </p>
              
            </div>
          </div>
        </div>
      </HeroContent>

      <SubHeader>
        <div className="header">
          <h2>
            Do something interesting that makes an
            <br />
            impact in your community.
          </h2>
        </div>
        <div className="header-content">
          <div className="img-container">
            <img src={solve} alt="solve" />
          </div>
          <div className="header-col">
            <p>
            This grant aims to cultivate innovative thinking among young people in Nigeria, encouraging them to address 
            (complex) societal issues/problems proactively and with a sense of responsibility. Personal grants will also be considered. 
            This year’s theme is open ended. Read about previous grantees on our <span><a
              href="https://mbbgrant.substack.com"
              target="_blank"
              rel="noreferrer"
              
            >
              newsletter
            </a></span>
            </p>
             {/* <p>
            Last year he wrote about his Ph.D journey in a memoir  <span><a
              href="https://bifarinthefifth.substack.com/p/wander"
              target="_blank"
              rel="noreferrer"
              
            >
              essay
            </a></span>. If you have read it and found it
              useful - and if you are able to - kindly consider donating to the
              fund <span>
              <a
              href="https://www.gofundme.com/f/sponsor-a-mbb-grantee-2022"
              target="_blank"
              rel="noreferrer"
              
            >
              here
            </a></span>. If you like to be a sponsor anyway, that's also fine!
            </p> */}
          </div>
        </div>
      </SubHeader>

      {/* <Goals>
        <div className="goalHeader">
          <h1>Goal</h1>
        </div>
        <div className="goal-txt">
          <p>
            There are two categories of projects Bifarin is looking to
            fund.
          </p>
        </div>
      </Goals>  */}

       {/* <Tracks>
        <div className="track-container">
          <div className="track-row">
            <div className="track-header">
              <img src={track} alt="track" />
            </div>
            <div className="track-subHeader">
              
              <h2>Science & Technology Track</h2>
            </div>
            <div className="track-content">
              <span>01</span>
              <p>
              This track is open to any project that espouses the philosophy of frugal innovation. With
up to 133 million Nigerians living in poverty, this means little or no access to
electricity, healthcare, and drinking water. We ought to be thinking aggressively about
how to innovate with scarce resources. I recommend all applicants to read my  <span><a
              href="https://bifarinthefifth.substack.com/p/frugal-innovation"
              target="_blank"
              rel="noreferrer"
              
            >
              essay
            </a></span> on
frugal innovation to inspire ideas on suitable projects, in addition to the resource I provide on frugal innovation  <span><a
              href="https://bifarinthefifth.substack.com/p/examples-of-frugal-innovation"
              target="_blank"
              rel="noreferrer"
              
            >
              examples
            </a></span>. 
              </p>
            
            </div>
          </div>
          <div className="track-row">
            <div></div>
            <div className="track-subHeader">
              
              <h2>Clinical Track</h2>
            </div>
            <div className="track-content">
              <span>02</span>
              <p>
              This track is open to Nursing students or interns. This track does not require frugal 
      
              innovation
             , however, projects that embodies the philosophy of frugal innovation will have priority. I recommend all applicants to read my  <span><a
              href="https://bifarinthefifth.substack.com/p/frugal-innovation"
              target="_blank"
              rel="noreferrer"
              
            >
              essay
            </a></span> on
frugal innovation to inspire ideas on suitable projects, in addition to the resource I provide on frugal innovation  <span><a
              href="https://bifarinthefifth.substack.com/p/examples-of-frugal-innovation"
              target="_blank"
              rel="noreferrer"
              
            >
              examples
            </a></span>.
              </p>
            </div>
          </div>
        </div>
      </Tracks>  */}
      <Footer />
    </div>
  );
};

const HeroSection = styled.section`
  padding: 11rem 4rem 0 4rem;
  background: #121212;
  .main-header {
    max-width: 45rem;
    padding: 2rem 0;
  }
  span {
    color: #ffe066;
  }
  .hr-sub-header p {
    line-height: 2;
  }
  .sub-header {
    padding: 1rem 0;
  }

  .main-header h1 {
    font-size: 3.5rem;
    line-height: 1.4;
  }
  .btn-apply {
    padding-top: 3rem;
    padding-bottom: 3rem;
    max-width: 13rem;
  }
  .btn-apply .apply {
    padding: 1.5rem 3.5rem;
    border: none;
    background: transparent;
    color: #ffe066;
    border: 1px solid #ffe066;
    display: block;
    transition: all 0.3s ease;
    text-decoration: none;
    &:hover {
      background: #ffe066;
      color: #121212;
    }
  }
  @media screen and (max-width: 765px) {
    width: 100%;
    padding: 6rem 2rem 0 2rem;
    .main-header {
      max-width: 100%;
    }
    .main-header h1 {
      font-size: 1.5rem;
    }
    .btn-apply {
      padding: 1rem 0;
    }
  }
`;

const HeroContent = styled.section`
  background: #f3f3f3;
  color: #000000;
  width: 100%;
  padding: 6rem 4rem 0 4rem;
  h2 {
    color: #2b2b2b;
    text-align: center;
  }
  .content-header {
    padding: 2rem 0;
  }
  .here {
    color: #7d82b8;
  }
  .content-1 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    align-items: center;
    .content-col {
      padding: 1rem;
      p {
        line-height: 1.6;
        color: #2b2b2b;
      }
      .txt {
        max-width: 45rem;
        margin: 0 auto;
        a {
          text-decoration: none;
          font-weight: 600;
          color: #2b2b2b;
        }
      }
    }
  }

  @media screen and (max-width: 765px) {
    padding: 3rem 1rem 2rem 1rem;
    .content-1 {
      display: flex;

      flex-direction: column;
      .content-col {
        padding: 0;
      }
    }
  }
`;

const SubHeader = styled.section`
  background: #f3f3f3;
  padding-top: 5rem;
  .header {
    text-align: center;
    max-width: 50rem;
    margin: 0 auto;
    h2 {
      color: #313131;
      font-weight: 500;
    }
  }
  .header-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    align-items: center;
    padding: 2rem 4rem 0 4rem;

    color: #2b2b2b;
    .header-col {
      max-width: 45rem;

      margin: 0 auto;
    }

    span {
      color: #7d82b8;
      font-weight: 600;
      a {
        color: #7d82b8;
        text-decoration: none;
        font-weight: 600;
      }
    }
  }
  @media screen and (max-width: 765px) {
    .header {
      max-width: 100%;
    }
    .header-content {
      padding: 3rem 1rem;
    }
  }
`;
// const Goals = styled.section`
//   text-align: center;
//   background: #f3f3f3;
//   padding: 3rem 0;
//   .goalHeader {
//     h1 {
//       color: #313131;
//     }
//   }
//   .goal-txt {
//     max-width: 45rem;
//     margin: 0 auto;
//     p {
//       color: #2b2b2b;
//     }
//   }
//   @media screen and (max-width: 765px) {
//     padding: 2rem 1rem;
//   }
// `;

// const Tracks = styled.section`
//   padding: 3rem 2rem;
//   .track-container {
//     .track-row {
//       .track-header {
//         text-align: center;
//         img {
//           width: 60rem;
//           height: 20rem;
//           object-fit: contain;
//         }
//       }
//       margin: 1rem 0;
//       h2 {
//         color: #313131;
//         padding: 1rem 0;
//       }

//       p,
//       li {
//         line-height: 1.6;
//         font-size: 1.1rem;
//       }
//       h5 {
//         font-weight: 600;
//       }
//       p,
//       h5 {
//         padding: 1.5rem 0;
//       }
//       ul {
//         padding: 1rem;
//       }

//       span {
//         color: #4c7e8f;
//       }
//     }
//     .track-content {
//       padding: 1.5rem;
//       background: #121212;
//       color: #f3f3f3;
//     }
//   }
//   @media screen and (max-width: 765px) {
//     padding: 0.7rem 1rem;
//     .track-container {
//       .track-row {
//         display: flex;
//         flex-direction: column;
//         .track-header {
//           img {
//             width: 100%;
//           }
//         }
//       }
//       .track-content {
//         padding: 1rem;
//       }
//     }
//   }
// `;

export default Home;
